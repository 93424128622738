import BrowserPage from "../components/v2/BrowserPage";
import Quotation from "../presentation/Quotation";
import QuotationModal from "../modals/QuotationModal";
import Filter, { ButtonOptionFilter, SelectOptionFilter, TextFilter } from "../components/v2/Filter";

const searchText = (pattern, item) => {
  return pattern == null || pattern.trim() === "" || item.quotationId?.includes(pattern) || item.name?.includes(pattern);
};

export const QuotationsPage = () => {
  return (
    <BrowserPage
      type={Quotation}
      Modal={QuotationModal}
      columns={(filter) => Quotation.metadata.columns}
      ninWidth="65em"
      canAdd={true}
      canEdit={true}
      itemsFilter={(x) => true}
      initialFilterState={{ text: null }}
      filterFunction={(filter, item) => {
        return searchText(filter.text, item);
      }}
      Filter={
        <Filter fontSize="0.5em">
          <TextFilter header="Search" name="text" width="100%" />
          <div />
        </Filter>
      }
      projectorsContext={{}}
    />
  );
};
