import "./App.css";
import { useEffect, useState, useMemo } from "react";
import { AuthContext } from "./lib/auth";
import { UserContext } from "./lib/userContext";
import {
  ConfidentialityProvider,
  useBuildConfidentialityContex,
} from "@lainco/react-toolbox/dist/components/confidentiality/confidentiality";
// Firebase Libraries
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider } from "firebase/auth";
import { getAuth, onAuthStateChanged, signInWithPopup } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
// Libraries
import { StorageContext } from "./lib/storage";
import { useGetById, usePatch } from "./lib/services";
// Models
import User from "./presentation/User";
// Components
import MainWindow from "./components/MainWindow";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
//Other
import { buildMenu } from "./menu";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const storage = getStorage(app);
if (process.env.REACT_APP_EMULATE_STORAGE === "true") connectStorageEmulator(storage, "localhost", 9199);

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ hd: "laincosoftware.com" });

function App() {
  const [user, setUser] = useState(null);
  const [appUser, setAppUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const conf = useBuildConfidentialityContex({
    focusLostTimeout: 180,
    lastActivityTimeout: 300,
    enabled: process.env.REACT_APP_HIDE_ON_LOST_FOCUS === "true",
  });

  const getUser = useGetById(User.metadata.url(""), User, auth.currentUser);
  const patchUser = usePatch(User.metadata.url(""), User, auth.currentUser);

  useEffect(() => {
    onAuthStateChanged(auth, (_user) => {
      if (_user == null) {
        signInWithPopup(auth, provider);
      } else {
        setUser(_user);
      }
    });
  }, []);

  useEffect(() => {
    if (user) {
      setLoadingUser(true);
      getUser(user.uid, (x) => {
        if (user.photoURL) patchUser(user.uid, { photo: user.photoURL });
        setAppUser(x);
        setLoadingUser(false);
      });
    } else {
      setAppUser(null);
      setLoadingUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, auth]);

  const menu = useMemo(() => buildMenu(appUser), [appUser]);

  return (
    <BrowserRouter>
      {user != null /*&& appUser?.enabled*/ && (
        <StorageContext.Provider value={storage}>
          <AuthContext.Provider value={user}>
            <UserContext.Provider value={appUser}>
              <ConfidentialityProvider value={conf}>
                <MainWindow menu={menu}></MainWindow>
              </ConfidentialityProvider>
            </UserContext.Provider>
          </AuthContext.Provider>
        </StorageContext.Provider>
      )}
      {user != null && !loadingUser && (appUser == null || !appUser.enabled) && (
        <div>Usuario no habilitado, por favor comuniquese con el administrador</div>
      )}
      {loadingUser && <div>Cargando Perfil...</div>}
      <Toaster position="top-center" reverseOrder={false} toastOptions={{ style: { borderRadius: "30px" } }} />
    </BrowserRouter>
  );
}

export default App;
