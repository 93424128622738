import { Fragment, useState, useEffect } from "react";
import { Common, icons } from "@lainco/react-toolbox";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import { Lock } from "@lainco/react-toolbox/dist/components/confidentiality/Lock";
import { useConfidentiality } from "@lainco/react-toolbox/dist/components/confidentiality/confidentiality";
import styled from "styled-components";
import isotipo from "../_assets/isotipo.png";
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader, SubMenu } from "react-pro-sidebar";
import { useAuth } from "../lib/auth";
import User from "./User";
import SellOrder from "../model/SellOrder";
import SellOrderMilestone from "../model/SellOrderMilestone";
import { useGet } from "../lib/services";
import ConfidentialContainer from "./ConfidentialContainer";

const RenderMenuItem = (item, index, prefix) =>
  item.link ? (
    <MenuItem key={index} icon={<span style={{ fontSize: "1.4em" }}>{item.icon}</span>}>
      {item.name || item.title}
      <Link to={item.link} />
    </MenuItem>
  ) : (
    <SubMenu key={index} title={item.title} icon={<span style={{ fontSize: "1.4em" }}>{item.icon}</span>}>
      {item.items.map((m, i) => RenderMenuItem(m, i))}
    </SubMenu>
  );

const RenderRoute = (item, index) =>
  item.link ? (
    <Route key={index} path={item.link} element={item.Component}>
      {item.hasType ? <Route path=":type/:id" element={item.Component} /> : <Route path=":id" element={item.Component} />}
    </Route>
  ) : (
    <Fragment key={index}>{item.items.map((m, i) => RenderRoute(m, i))}</Fragment>
  );

const RenderTitle = (item, index) =>
  item.link ? (
    <Route key={index} path={item.link} element={<Title>{item.title}</Title>}>
      <Route path=":id" element={<Title>{item.title}</Title>} />
    </Route>
  ) : (
    <Fragment key={index}>{item.items.map((m, i) => RenderTitle(m, i))}</Fragment>
  );

export default function MainWindow({ menu }) {
  const [collapsed, setCollapsed] = useState(true);
  const { locked, lock, unlock, lastActivityCountdown } = useConfidentiality();
  const auth = useAuth();
  const environmentColor = process.env.REACT_APP_ENVIRONMENT_COLOR;
  const environmentName = process.env.REACT_APP_ENVIRONMENT_NAME;
  const environmentBackground = process.env.REACT_APP_ENVIRONMENT_BACKGROUND;
  const localStorage = process.env.REACT_APP_EMULATE_STORAGE === "true";
  //Inicializo items
  const initializers = [useGet("sellOrders", SellOrder), useGet("sellOrderMilestones", SellOrderMilestone)];

  useEffect(() => {
    initializers.forEach((f) => f());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shownMenuItems = menu.filter((m) => m.visible == null || m.visible);

  return (
    <ConfidentialContainer
      hideOnFocusLost={process.env.REACT_APP_HIDE_ON_LOST_FOCUS === "true"}
      hideInterval={parseInt(process.env.REACT_APP_HIDE_ON_LOST_FOCUS_TIMEOUT)}
    >
      <Window cols="auto 1fr">
        <SidePanel backgroundColor={environmentBackground}>
          <ProSidebar collapsed={collapsed}>
            <SidebarHeader>
              <Logo>
                <IsoTipo src={isotipo} />
                {!collapsed && "Procurement"}
              </Logo>
            </SidebarHeader>
            <SidebarContent>
              <Menu>{shownMenuItems.map((m, i) => RenderMenuItem(m, i))}</Menu>
            </SidebarContent>
            {!collapsed && (
              <SidebarFooter>
                <Info>
                  <span>@ 2022 Lainco LLC, Lainco Software SA</span>
                  <span>Version {process.env.REACT_APP_VERSION}</span>
                </Info>
              </SidebarFooter>
            )}
          </ProSidebar>
          <CollapseButton onClick={() => setCollapsed(!collapsed)}>
            <Chevron collapsed={collapsed}>{icons.chevronLeft()}</Chevron>
          </CollapseButton>
        </SidePanel>
        <Container rows="auto 1fr" padding="1em">
          <Header cols="auto auto auto 1fr auto auto">
            <Routes>{shownMenuItems.map((m, i) => RenderTitle(m, i))}</Routes>
            {environmentName !== "PROD" ? <Tag color={environmentColor}>{environmentName}</Tag> : <div />}
            {localStorage ? <Tag color={environmentColor}>Storage Emulated</Tag> : <div />}
            <div />
            <div style={{ fonstSize: "1.2em", alignSelf: "center" }}>
              {process.env.REACT_APP_HIDE_ON_LOST_FOCUS === "true" ? (
                <Lock
                  visible={!locked}
                  onLockRequested={lock}
                  onUnlockRequested={unlock}
                  icon="lock"
                  show="click-state"
                  showCountdown={true}
                  countDown={lastActivityCountdown}
                />
              ) : (
                <div />
              )}
            </div>
            <User name={auth.displayName} imageUrl={auth.photoURL} />
          </Header>
          <Content>
            <Routes>
              <Route exact path="/" element={<Navigate to="/inbox" replace />} />
              {shownMenuItems.map((m, i) => RenderRoute(m, i))}
            </Routes>
          </Content>
        </Container>
      </Window>
    </ConfidentialContainer>
  );
}

const Window = styled(Common.Row)`
  min-height: 100vh;
`;

const Container = styled(Common.Col)`
  position: relative;
  background: #fafafa;
  max-height: 100vh;
`;

const Chevron = styled.div`
  transform: scaleX(${(props) => (props.collapsed ? "-1" : "1")});
  transition: 500ms;
`;

const Tag = styled.div`
  background: ${(props) => props.color};
  align-self: center;
  font-size: 0.7em;
  border-radius: 0.3em;
  color: white;
  padding: 0.3em 0.5em;
`;

const Title = styled.h1`
  color: #777;
  font-size: 1.4em;
  font-weight: 600;
  margin: 0;
  align-self: center;
`;

const Header = styled(Common.Row)`
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.4em;
  align-items: baseline;
  grid-gap: 1em;
`;

const Content = styled.div`
  overflow: auto;
`;

const SidePanel = styled.div`
  display: grid;
  position: relative;

  &&& > .pro-sidebar > .pro-sidebar-inner  {
    ${(props) => (props.backgroundColor ? `background: ${props.backgroundColor};` : "")}
  }
`;

const Logo = styled.div`
  display: grid;
  font-size: 1.5em;
  grid-template-columns: auto 1fr;
  grid-gap: 0.3em;
  font-family: Montserrat;
  padding: 0.6em;
  align-items: center;
  align-content: center;
`;

const IsoTipo = styled.img`
  width: 2em;
`;

const CollapseButton = styled.div`
  display: grid;
  position: absolute;
  top: 3.4em;
  right: -1em;
  border-radius: 1em;
  width: 2em;
  height: 2em;
  background: white;
  border: 1px solid #444;
  z-index: 2000;
  align-content: center;
  justify-content: center;
`;

const Info = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 1em;
  justify-items: center;
  font-size: 0.5em;
  padding: 1em;
`;
