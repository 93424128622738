import { format, intervalToDuration, parseISO } from "date-fns";
import { groupBy, min } from "lodash";
import React from "react";
import { useCandidates } from "../app/candidatesSlice";
import Due from "../components/Due";

const wordsToTokens = (words) => [...words.matchAll(/(^|\s*|-|_)(\w+)/g)].map((x) => x[2]);
const caseToTokens = (caseExpression) => [...caseExpression.matchAll(/(^[a-z0-9]+|[A-Z][a-z0-9]*)/g)].map((x) => x[1]);
const tokenCased = (token) => token.replace(/(\W)(\w*)/i, (m, p0, p1) => `${p0.toUpperCase()}${p1.toLowerCase()}`);

export const fromKaebCase = (kaebCase) => wordsToTokens(kaebCase);
export const fromSnapCase = (snapCase) => wordsToTokens(snapCase);
export const fromCamelCase = (camelCase) => caseToTokens(camelCase);
export const fromPascalCase = (pascalCase) => caseToTokens(pascalCase);
export const fromWords = (words) => wordsToTokens(words);

export const toKaebCase = (tokens) => tokens.map((t) => t.toLowerCase()).join("-");
export const toSnapCase = (tokens) => tokens.map((t) => t.toLowerCase()).join("_");
export const toPascalCase = (tokens) => tokens.map((t) => tokenCased(t)).join("");
export const toCamelCase = (tokens) => tokens.map((t, i) => (i === 0 ? t.toLowerCase() : tokenCased(t))).join("");
export const toWords = (tokens) => tokens.map((t, i) => (i === 0 ? t.tokenCased() : t.toLowerCase())).join(" ");

export const parseDate = (date) => (date == null ? null : parseISO(date));
export const formatDate = (date) => (date ? format(date, "dd/MM/yyyy") : null);
export const formatUnit = (number, unit) => (number ? `${number} ${unit}` : "-");

export const inject = (element, props) => (React.isValidElement(element) ? React.cloneElement(element, props) : element);

export const injectAll = (elements, props) => (elements ? React.Children.map(elements, (e) => inject(e, props)) : elements);

export const buildOptions = (fields, values) =>
  values.map((value, valueIndex) => ({
    id: valueIndex + 1,
    ...Object.fromEntries(fields.map((field, fieldIndex) => [field, typeof value === "string" ? value : value[fieldIndex]])),
  }));

export const merge = (a, b) =>
  a == null && b == null ? "-" : a != null && b == null ? a : a == null && b != null ? b : a === b ? a : `${a} / ${b}`;

export const useOptions = (code, append, decorate, filter) => {
  const [candidates, loading] = useCandidates(code);
  const filtered = filter ? candidates?.filter((x) => filter(x)) : candidates;
  const options = [
    ...append.map((x, i) => ({ id: i, ...x })),
    ...(filtered ? (decorate ? filtered.map((x) => decorate(x)) : filtered) : []),
  ];
  return [options, loading];
};

export const formatDelta = (delta) => <Due width="7em" delta={-delta} />;

export const fileNameSplit = (name) => {
  const res = /(^(.*)\.([^.]*)$|^([^.]*)$)/gm.exec(name);
  if (res[4]) return { name: res[4], extension: "" };
  else return { name: res[2], extension: res[3] };
};

export const arrayJoin = (array, sep) => array.map((element, i) => (i === 0 ? element : [sep, element])).flat();

export const convert = (date, currency, quantity, ars, dest, prices) => {
  const _date = date ? format(date, "yyyy-MM-dd") : null;
  let fx1 = currency.id === ars.id ? 1 : prices.find((p) => (date == null || p.date <= _date) && p.currency.id === currency.id)?.price;
  if (fx1 == null && _date != null) {
    //No hay un fx tan viejo, me traigo el mas viejo
    const d = min(prices.filter((p) => p.currency.id === currency.id).map((p) => p.date));
    fx1 = prices.find((p) => p.date === d && p.currency.id === currency.id)?.price;
  }
  let fx2 = dest.id === ars.id ? 1 : prices.find((p) => (date == null || p.date <= _date) && p.currency.id === dest.id)?.price;
  if (fx2 == null && _date != null) {
    //No hay un fx tan viejo, me traigo el mas viejo
    const d = min(prices.filter((p) => p.currency.id === dest.id).map((p) => p.date));
    fx2 = prices.find((p) => p.date === d && p.currency.id === dest.id)?.price;
  }
  const result = (quantity * fx1) / fx2;
  return dest.precision ? Math.round(result * Math.pow(10, dest.precision)) / Math.pow(10, dest.precision) : result;
};

export const linefeedToBr = (string, f) =>
  string
    ? string
        .replace("\r\n", "\n")
        .replace("\n", "\n<br />\n")
        .split("\n")
        .map((x) => (x === "<br />" ? <br /> : <span>{f ? f(x) : x}</span>))
    : null;

const zeroPad = (num) => String(num).padStart(2, "0");

export const formatCountdown = (seconds) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  if (duration.hours > 0) return `${zeroPad(duration.hours)}:${zeroPad(duration.minutes)}:${zeroPad(duration.seconds)}`;
  else return `${zeroPad(duration.minutes)}:${zeroPad(duration.seconds)}`;
};

export const buildTree = (array, groupings) => {
  if (groupings.length === 0) return array;
  const [grouping, ...rest] = groupings;
  return Object.values(groupBy(array, (a) => grouping.id(grouping.key(a)))).map((x) => ({
    key: grouping.key(x[0]),
    items: buildTree(x, rest),
  }));
};

/**
 *
 * @param {*} tree
 * @param {*} map
 * @returns
 */

export const mapTree = (tree, leaf, branch) => {
  return mapNode(tree, leaf, branch, 0).node;
};

const mapNode = (node, leaf, branch, level) => {
  if (node.items) {
    const result = node.items.map((n) => mapNode(n, leaf, branch, level + 1));
    const items = result.map((r) => r.node);
    const depth = result.reduce((a, b) => Math.max(a, b.depth), 0) + 1;
    const res = { node: branch(node, items, level, depth), depth };
    return res;
  } else return { node: leaf(node, level), depth: 0 };
};

export const formatAmount = (amount, options = {}) => {
  const { currency, precision } = options;
  const _precision = precision ?? currency?.precision ?? 0;

  const number = isNaN(parseFloat(amount))
    ? "-"
    : parseFloat(amount) === 0
    ? "-"
    : parseFloat(Math.abs(amount)).toLocaleString(undefined, {
        minimumFractionDigits: _precision,
        maximumFractionDigits: _precision,
      }) || "-";

  return amount < 0 ? <span style={{ color: "red" }}>({number})</span> : <span>{number}&nbsp;</span>;
};

export const formatPercentage = (amount, options = { precision: 2 }) => {
  const { currency, precision } = options;
  const _precision = precision ?? currency?.precision ?? 0;

  const number = isNaN(parseFloat(amount))
    ? "-"
    : parseFloat(amount) === 0
    ? "-"
    : parseFloat(Math.abs(amount)).toLocaleString(undefined, {
        minimumFractionDigits: _precision,
        maximumFractionDigits: _precision,
      }) || "-";

  return amount < 0 ? <span style={{ color: "red" }}>({number} %)</span> : <span>{number}&nbsp;%&nbsp;</span>;
};
