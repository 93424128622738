import React from "react";
import BrowserPage from "../components/v2/BrowserPage";
import IndexValue from "../presentation/IndexValue";

// CONTROL

export const IndexValuesPage = () => {
  const columns = IndexValue.metadata.columns;

  return <BrowserPage columns={() => [columns.date, columns.index, columns.value]} type={IndexValue} minWidth="40em" />;
};
