import React from "react";
import { useCandidates } from "../app/candidatesSlice";
import Amount from "../components/Amount";
import { FollowUpTag } from "../components/FollowUp";
import Tag from "../components/Tag";
import BrowserPage from "../components/v2/BrowserPage";
import Filter, { ButtonOptionFilter, SelectOptionFilter, TextFilter } from "../components/v2/Filter";
import { buildOptions, merge, useOptions } from "../lib/misc";
import SellOrderModal from "../modals/SellOrderModal";
import { getPrice } from "../presentation/Price";
import SellOrder, { isResponsible, requiredActions } from "../presentation/SellOrder";
import { hasRole } from "../presentation/User";

// COLUMNS
const cols = {
  company: {
    title: "Company",
    align: "center",
    content: { projectors: (x) => merge(x?.company?.code, x?.beneficiary?.code) },
  },
  responsible: {
    title: "Responsible",
    align: "center",
    content: {
      projectors: (x) => merge(x?.accountManager?.initials, x?.projectLeader?.initials),
    },
  },
  accountManager: {
    title: "Account Manager",
    align: "center",
    content: { name: "accountManager.initials" },
  },
  accountManagerOk: {
    title: "Account Manager Ok?",
    align: "center",
    content: { projectors: (x) => `${x?.accountManagerOk ? "YES" : "NO"} (by ${x?.accountManager?.initials || ""})` },
  },
  projectLeader: {
    title: "Project Leader",
    align: "center",
    content: { name: "projectLeader.initials" },
  },
  projectLeaderOk: {
    title: "Project Leader Ok?",
    align: "center",
    content: { projectors: (x) => `${x?.projectLeaderOk ? "YES" : "NO"} (by ${x?.projectLeader?.initials || ""})` },
  },
  customer: { title: "Customer", content: { name: "customer.shortName" } },
  category: { title: "Category", content: { name: "category.name" } },
  sellOrder: { title: "Sell Order", content: { name: "name" } },
  product: {
    title: "Product",
    content: {
      projectors: (x) => {
        return (
          x.project?.name ?? (
            <Tag fontSize="1.5em" color="#f84">
              Not Set
            </Tag>
          )
        );
      },
    },
  },
  customerId: { title: "Order #", align: "right", content: { name: "customerId" } },
  status: {
    title: "Status",
    align: "center",
    content: {
      projectors: (x) => x.getStatus(),
    },
  },
  amount: {
    title: "Amount",
    align: "right",
    content: {
      projectors: (x, c) => {
        return <Amount precision={0} {...x.amount} />;
        /*

        const color = c?.currencies?.find((c) => c.id === x?.amount?.currency?.id)?.color || "#ccc";
        return (
          <div style={{ fontSize: "1.5em" }}>
            <Tag color={color}>
              <div style={{ fontWeight: 500 }}>{x.getShownAmount()}</div>
            </Tag>
          </div>
        );*/
      },
    },
  },
  terms: { title: "Terms", align: "center", content: { projectors: (x) => x?.terms?.name || "-" } },
  adjustmentClause: {
    title: "Adjustment Clause",
    align: "center",
    content: {
      projectors: (x, c) => {
        const value = x?.getShownAdjustementClause();
        if (value === "-" || c.prices == null || c.clauses == null) return value;
        if (x.adjustmentClauseTrigger?.code === "AUTO")
          return (
            <Tag fontSize="1.5em" color="#999">
              {value}
            </Tag>
          );

        const clause = c.clauses.find((t) => x.adjustmentClause.id === t.id);
        const [price] = getPrice(c.prices, clause?.currency, clause?.priceType);
        const capOk = price == null || x?.adjustmentClauseCap == null || parseFloat(x.adjustmentClauseCap) >= price;
        const floorOk = price == null || x?.adjustmentClauseFloor == null || parseFloat(x.adjustmentClauseFloor) <= price;
        if (!capOk || !floorOk)
          return (
            <Tag fontSize="1.5em" color={floorOk ? "red" : "orange"}>
              {value}
            </Tag>
          );
        return (
          <Tag fontSize="1.5em" color="#8ca">
            {value}
          </Tag>
        );
      },
    },
  },
  pending: {
    title: "Pending",
    align: "right",
    content: {
      projectors: (x, c) => {
        return <Amount amount={x.getTotalPending()} currency={x?.amount?.currency} precision={0} />;
      },
    },
  },
  invoiced: {
    title: "Waiting Settlement",
    align: "right",
    content: {
      projectors: (x, c) => <Amount amount={x.getTotalInvoiced()} currency={x?.amount?.currency} precision={0} />,
    },
  },
  actions: {
    title: "Actions",
    content: {
      projectors: (x, c) => {
        const user = c.users.find((u) => u.id === c.filter?.responsible);
        const actions = requiredActions(x, user);
        return (
          <ul style={{ listStyle: '"- "' }}>
            {actions.map((a) => (
              <li style={{ fontWeight: 400 }}>{a}</li>
            ))}
          </ul>
        );
      },
    },
  },
  followUp: {
    title: "Follow Up",
    width: "15%",
    content: {
      projectors: (x) =>
        x?.followUp?.hasFollowup && x?.followUp?.dueDate ? (
          <FollowUpTag
            style={{ fontSize: "0.7em" }}
            hasFollowup={true}
            dueDate={x.followUp.dueDate}
            maxLen={50}
            nextTask={x.followUp.nextTask}
          />
        ) : (
          "-"
        ),
    },
  },
};

// OPTIONS
const impediments = buildOptions(["name"], ["All", "No Impediment", "With Impediment"]);

// CONTROL
export const SellOrdersPage = ({ status, initialDue, columns, dueType }) => {
  const [responsibles, responsiblesLoading] = useOptions(
    "users",
    [{ name: "All", shortName: "All" }],
    (x) => ({
      ...x,
      shortName: x.initials,
      name: x.initials,
    }),
    (x) => hasRole(x, "Project Leader") || hasRole(x, "Account Manager") || hasRole(x, "Administrative")
  );

  const [customers, customersLoading] = useOptions("customers", [{ name: "All Customers", shortName: "All" }], (x) => ({
    ...x,
    name: x.shortName,
  }));
  const [companies, companiesLoading] = useOptions("companies", [{ name: "All Companies", shortName: "All" }], (x) => ({
    ...x,
    shortName: x.code,
  }));
  const [currencieOptions, currencieOptionsLoading] = useOptions("currencies", [{ name: "All Currencies", shortName: "All" }], (x) => ({
    ...x,
    shortName: x.symbol,
  }));
  const [usersOptions, usersOptionsLoading] = useOptions(
    "users",
    [{ name: "All", shortName: "All" }],
    (x) => ({
      ...x,
      shortName: x.initials,
    }),
    (x) => x?.roles?.find((r) => r === "Account Manager") || x?.roles?.find((r) => r === "Project Leader")
  );
  const [categories, categoriesLoading] = useOptions("incomeCategories", [{ name: "All" }]);
  const [adjustments, adjustmentsLoading] = useOptions("adjustmentTypes", [{ name: "All" }, { name: "No Clause" }, { name: "With Clase" }]);
  const [currencies] = useCandidates("currencies");
  const [prices] = useCandidates("prices");
  const [clauses] = useCandidates("adjustmentTypes");
  const [users] = useCandidates("users");
  const [statuses] = useOptions(
    "statuses",
    Array.isArray(status)
      ? [{ name: "All" }, ...status.map((x) => ({ x, all: SellOrder.statuses, name: SellOrder.statuses[x].name }))]
      : [{ name: "All" }, { name: "Open" }, { name: "Closed" }, { name: "Canceled" }]
  );

  return (
    <BrowserPage
      Modal={SellOrderModal}
      columns={columns}
      type={SellOrder}
      minWidth="65em"
      initialFilterState={{
        due: dueType === "nextEvent" ? initialDue || 3 : 4,
        customer: 0,
        customerId: "",
        company: 0,
        beneficiary: 0,
        currency: 0,
        projectLeader: 0,
        accountManager: 0,
        category: 0,
        nextEvent: dueType === "settlement" ? 2 : 4,
        adjustmentClause: 0,
        impediment: 1,
        responsible: 0,
        status: status == null ? 1 : 0,
      }}
      filterFunction={(filter, item) => {
        return (
          (filter.customerId == null || filter.customerId.trim() === "" || item?.customerId?.indexOf(filter.customerId.trim()) !== -1) &&
          (filter.attachment == null ||
            filter.attachment.trim() === "" ||
            item?.attachments?.find((a) => a.name.indexOf(filter.attachment.trim()) !== -1)) &&
          (filter.customer === 0 || item?.customer?.id === filter.customer) &&
          (filter.company === 0 || item?.company?.id === filter.company) &&
          (filter.beneficiary === 0 || item?.beneficiary?.id === filter.beneficiary) &&
          (filter.currency === 0 || item?.amount?.currency?.id === filter.currency) &&
          (filter.category === 0 || item?.category?.id === filter.category) &&
          (filter.accountManager === 0 || item?.accountManager?.id === filter.accountManager) &&
          (filter.projectLeader === 0 || item?.projectLeader?.id === filter.projectLeader) &&
          (filter.adjustmentClause === 0 ||
            (filter.adjustmentClause === 1 && item?.adjustmentClause == null) ||
            (filter.adjustmentClause === 2 && item?.adjustmentClause != null) ||
            item?.adjustmentClause?.id === filter.adjustmentClause) &&
          (filter.impediment === 1 ||
            (filter.impediment === 2 && !item?.milestones?.filter((m) => m.hasFlag)?.length) ||
            (filter.impediment === 3 && item?.milestones?.filter((m) => m.hasFlag)?.length)) &&
          (filter.responsible === 0 ||
            isResponsible(
              item,
              responsibles.find((r) => r?.id === filter.responsible)
            )) &&
          (filter.status === 0 ||
            (status == null &&
              ((filter.status === 1 && item?.status !== "closed" && item?.status !== "canceled") ||
                (filter.status === 2 && item?.status === "closed") ||
                (filter.status === 3 && item?.status === "canceled"))) ||
            (Array.isArray(status) && item?.status === status[filter.status - 1]))
        );
      }}
      Filter={
        <Filter fontSize="0.5em">
          <div />
          <TextFilter header="Order #" name="customerId" />
          <TextFilter header="Attachment" name="attachment" />
          <ButtonOptionFilter header="Responsible" name="responsible" options={responsibles} loading={responsiblesLoading} />
          {(status == null || Array.isArray(status)) && <ButtonOptionFilter header="Status" name="status" options={statuses} />}
          <SelectOptionFilter
            header="Account Manager"
            name="accountManager"
            options={usersOptions}
            loading={usersOptionsLoading}
            showValue="shortName"
          />
          <SelectOptionFilter
            header="Project Leader"
            name="projectLeader"
            options={usersOptions}
            loading={usersOptionsLoading}
            showValue="shortName"
          />
          <SelectOptionFilter header="Adj. Clause" name="adjustmentClause" options={adjustments} loading={adjustmentsLoading} />
          <SelectOptionFilter header="With Impediment" name="impediment" options={impediments} />
          <SelectOptionFilter header="Category" name="category" options={categories} loading={categoriesLoading} />
          <SelectOptionFilter
            header="Currency"
            name="currency"
            options={currencieOptions}
            loading={currencieOptionsLoading}
            showValue="shortName"
          />
          <SelectOptionFilter header="Customer" name="customer" options={customers} loading={customersLoading} showValue="shortName" />
          <SelectOptionFilter header="Company" name="company" options={companies} loading={companiesLoading} showValue="shortName" />
          <SelectOptionFilter
            header="Beneficiary"
            name="beneficiary"
            options={companies}
            loading={companiesLoading}
            showValue="shortName"
          />
        </Filter>
      }
      itemsFilter={(x) =>
        !x.isDeleted && (status ? (Array.isArray(status) ? status.filter((s) => s === x.status).length > 0 : x.status === status) : true)
      }
      projectorsContext={{ currencies, prices, clauses, users }}
    />
  );
};

export const SellOrdersAllPage = () => (
  <SellOrdersPage
    columns={(filter) => [
      cols.company,
      cols.customer,
      cols.product,
      cols.customerId,
      cols.sellOrder,
      cols.status,
      cols.followUp,
      ...(filter.responsible !== 0 ? [cols.actions] : []),
      cols.amount,
      cols.pending,
      cols.invoiced,
      cols.adjustmentClause,
    ]}
  />
);

export const SellOrdersDraftPage = () => (
  <SellOrdersPage
    status="draft"
    columns={(filter) => [
      cols.company,
      cols.customer,
      cols.customerId,
      cols.sellOrder,
      cols.followUp,
      ...(filter.responsible !== 0 ? [cols.actions] : []),
      cols.amount,
      cols.pending,
      cols.invoiced,
      cols.adjustmentClause,
    ]}
  />
);

export const SellOrdersPendingApprovalPage = () => (
  <SellOrdersPage
    status="pendingApproval"
    columns={(filter) => [
      cols.company,
      cols.customerId,
      cols.accountManagerOk,
      cols.projectLeaderOk,
      cols.customer,
      cols.sellOrder,
      cols.followUp,
      ...(filter.responsible !== 0 ? [cols.actions] : []),
      cols.amount,
      cols.pending,
      cols.invoiced,
      cols.adjustmentClause,
    ]}
  />
);

export const SellOrdersAdminReviewPage = () => (
  <SellOrdersPage
    status="adminReview"
    columns={(filter) => [
      cols.company,
      cols.customerId,
      cols.accountManagerOk,
      cols.projectLeaderOk,
      cols.customer,
      cols.sellOrder,
      cols.followUp,
      ...(filter.responsible !== 0 ? [cols.actions] : []),
      cols.amount,
      cols.pending,
      cols.invoiced,
      cols.adjustmentClause,
    ]}
  />
);

export const SellOrdersInProgressPage = () => (
  <SellOrdersPage
    status="inProgress"
    columns={(filter) => [
      cols.company,
      cols.customer,
      cols.customerId,
      cols.sellOrder,
      cols.followUp,
      ...(filter.responsible !== 0 ? [cols.actions] : []),
      cols.amount,
      cols.pending,
      cols.invoiced,
      cols.adjustmentClause,
    ]}
  />
);

export const SellOrdersClossedPage = () => (
  <SellOrdersPage
    status={["closed", "canceled"]}
    columns={(filter) => [
      cols.company,
      cols.customer,
      cols.customerId,
      cols.sellOrder,
      cols.status,
      cols.followUp,
      ...(filter.responsible !== 0 ? [cols.actions] : []),
      cols.amount,
      cols.pending,
      cols.invoiced,
      cols.adjustmentClause,
    ]}
  />
);
